.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.App-inner {
  padding: 20px;
  max-width: 1024px;
  width: 100%;
}

.App-logo {
  height: 200px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-form {
  position: relative;
  display: block;
  background: rgba(255,255,255,0.1);
  padding: 20px 30px;

}

.App-loader {
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(43, 62, 80, 0.6); */
  padding: 20px 30px;

  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.App-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(43, 62, 80, 0.9);
  padding: 20px 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 10;
}

.App-loader p {
  margin-top: 10px;
  font-size: 20px;

}

.App-loader-overlay p {
  margin-top: 10px;
  font-size: 20px;

}

.App-error-message {
  width: 100%;
  max-width: 1024px;
  text-align: center;
}

.App-top-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom:10px;
  flex-wrap: wrap;
}
.App-top-buttons .btn {
  margin: 10px;
}

.App-confirm-delete-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App-auth-form {
  padding: 10px;
  display: flex;
  flex-direction: row;

}
.App-login-button {
  width: fit-content;
  margin: 1px 20px 1px 0px;
}

.App-more-margin-top {
  margin-top: 20px;
}

.App-even-more-margin-top {
  margin-top: 30px;
}

.App-server-error {
  margin-top: 20px;
  color: red;
  font-weight: bold;
}

.App-uid-area {
  padding: 16px 20px 4px 20px;
  margin: 30px 10px 40px 10px;
  color: #333;
  background: rgba(255,255,255, 0.5);
  border-radius: 3px;
}

.input-field {
  padding: 10px;
  /* margin: 10px; */
  /* margin-bottom: 10px; */
  min-width: 300px;
}

.input-field:focus {
  outline: none;
}

.input-field:invalid {
  border: 2px solid red;
}

.input-field:valid {
  border: 1px solid green;
}

.password-error-message {
  color: red;
}

.invalid-feedback {
  color: #f0ad4e;
}

.is-invalid {
  border: 2px solid red;
}

input[type=checkbox].is-invalid {
  outline: 2px solid red;
}

.modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0,0,0,0.7);
  padding-top: 200px;
}

.modal-content {
  max-width: 600px;
}

.btn-secondary {
  color: #444;
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus {
  background-color: #ccc;
  text-decoration: none;
}

.tos-group {
  margin-top: 20px;
  margin-bottom: 30px;
}

.tos-group-element {
  width: 100%;
  margin: 0;
}


.form-check-input {
  margin-right: 10px !important;
}

.form-check {
  display: flex;
  align-items: flex-start;
}

.invalid-feedback {
  margin-top: 4px;
}

.button-margin {
  margin: 10px;
}

#root > .form-group {
  margin-top: 60px;
  position: relative;
  
}

#root > .form-group::after {
  content: '';
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  width: 8px;
}


#root > .form-group:nth-child(2n)::after {
  background-color: #d9534f;  
}

#root > .form-group:nth-child(2n+1)::after {
  background-color: #5bc0de;  
}
