.ImageUploader {
  width: 100%;
  /* height: 100px; */
  /* background-color: rgba(255,255,255,0.5); */
  /* border: 1px black dotted; */
}

.ImageUploader-Upload {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.ImageUploader-Uploading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
}

.ImageUploader-Uploading p {
  margin: 0;
  margin-left: 10px;
  color: #ddd;

}

.ImageUploader-Icon {
  width: 40px;
  height: 40px;
  color: #ddd;
  margin-right: 10px;
}

.ImageUploader input[type=file] { 
  visibility: hidden;
  position: absolute
}

.ImageUploader-container {
  position: relative;
}

.ImageUploader-remove {
  position: absolute;
  width: 30px;
  height: 30px;
  color: red;
  top: -15px;
  right: -15px;
}
.ImageUploader-remove svg {
  width: 100%;
  height: 100%;
  color: inherit;
}

.ImageUploader img {
  max-width: 200px;
  max-height: 200px;
}

.ImageUploader-error {
  color: red
}